import { schema } from 'normalizr'

import Model from './model'

class User extends Model({
  authToken: undefined,
  verificationToken: undefined,
  id: undefined,
  getRoles: undefined,
  email: undefined,
  termsAccepted: undefined,
  phone: undefined,
  agency: undefined,
  cities: undefined,
  agencySlug: undefined,
  lgpdLinks: undefined,
  name: undefined,
  widgetsOptions: undefined,
  getServiceProviderId: undefined,
  registrationConfirmed: undefined,
  lastSelectedModule: undefined,
  companyName: undefined,
  tips: undefined,
  tour: undefined,
  welcomeTourWeb: false,
  classification: undefined,
  externalCode: undefined,
  userOnboarding: undefined,
  viewOnlyMyCompanyTickets: false,
  activeProfile: false,
  profiles: [],
  isAdmin: false,
  isActive: false,
  permissions: {},
  isTemporaryPassword: false,
}) {}

const userNewSchema = new schema.Entity(
  'user',
  {},
  {
    processStrategy: entity => new User(entity),
  }
)

const userSchema = [userNewSchema]

export { userSchema, User as default }
