import { schema, normalize } from 'normalizr'
import { Map } from 'immutable'

import Model from './model'
import Bank from './bank'
import { categorySchema } from './category'

class Provider extends Model({
  acceptsPix: undefined,
  address: undefined,
  bank: undefined,
  bankAccountCpfCnpj: undefined,
  bankAccountOwnerName: undefined,
  bankAccountType: undefined,
  bankAccountWithDigit: undefined,
  bankAccountNumber: undefined,
  bankAccountDigit: undefined,
  bankAgencyNumber: undefined,
  brandLogoPngUrl: undefined,
  blockedAgency: undefined,
  blockedContractDate: undefined,
  blockFinanceTransfers: undefined,
  cep: undefined,
  city: undefined,
  cnpj: undefined,
  companyCreationDate: undefined,
  companyCreationDateBr: undefined,
  companyAttachments: undefined,
  condolivreStatus: undefined,
  contractAttachments: undefined,
  contactEmail: undefined,
  whatsappConnectionId: undefined,
  budgeteerConnectionId: undefined,
  contractStatus: undefined,
  extraAddressInfo: undefined,
  generateContractDate: undefined,
  getContractOptions: undefined,
  id: undefined,
  legalName: undefined,
  mainBlockedAgencies: undefined,
  mainBlockedAgenciesNames: undefined,
  mainServiceNames: undefined,
  mainServices: undefined,
  mainSpecialSkills: undefined,
  name: undefined,
  nearbyLandmark: undefined,
  neighborhood: undefined,
  numberOfEmployees: undefined,
  personResponsibleName: undefined,
  phone: undefined,
  onboardingStage: undefined,
  pixKey: undefined,
  pixKeyChoice: undefined,
  cities: undefined,
  registrationStatus: undefined,
  state: undefined,
  streetNumber: undefined,
  staffUsers: undefined,
  tradesmanId: undefined,
  lastAssignmentTime: undefined,
  taxRegime: undefined,
  priorityForEmergencies: undefined,
  messageNotAllowedToUpdate: undefined,
  providesRefera: false,
  contractType: undefined,
  round: undefined,
  level: undefined,
}) {}

const providerNewSchema = new schema.Entity(
  'provider',
  {},
  {
    processStrategy: entity => {
      const normalizedService = normalize(entity.mainServices, categorySchema)
      return new Provider(entity)
        .set('bank', new Bank(entity.bank))
        .set('mainServices', Map(normalizedService.entities.service))
    },
  }
)

const providerSchema = [providerNewSchema]

export { providerSchema, Provider as default }
